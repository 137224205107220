// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-map-of-off-plan-developments-index-js": () => import("./../../../src/pages/map-of-off-plan-developments/index.js" /* webpackChunkName: "component---src-pages-map-of-off-plan-developments-index-js" */),
  "component---src-pages-map-of-properties-for-rent-index-js": () => import("./../../../src/pages/map-of-properties-for-rent/index.js" /* webpackChunkName: "component---src-pages-map-of-properties-for-rent-index-js" */),
  "component---src-pages-map-of-properties-for-sale-index-js": () => import("./../../../src/pages/map-of-properties-for-sale/index.js" /* webpackChunkName: "component---src-pages-map-of-properties-for-sale-index-js" */),
  "component---src-pages-off-plan-developments-index-js": () => import("./../../../src/pages/off-plan-developments/index.js" /* webpackChunkName: "component---src-pages-off-plan-developments-index-js" */),
  "component---src-pages-properties-for-rent-index-js": () => import("./../../../src/pages/properties-for-rent/index.js" /* webpackChunkName: "component---src-pages-properties-for-rent-index-js" */),
  "component---src-pages-properties-for-sale-index-js": () => import("./../../../src/pages/properties-for-sale/index.js" /* webpackChunkName: "component---src-pages-properties-for-sale-index-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-templates-area-guide-template-js": () => import("./../../../src/templates/area-guide-template.js" /* webpackChunkName: "component---src-templates-area-guide-template-js" */),
  "component---src-templates-calculator-template-js": () => import("./../../../src/templates/calculator-template.js" /* webpackChunkName: "component---src-templates-calculator-template-js" */),
  "component---src-templates-career-template-js": () => import("./../../../src/templates/career-template.js" /* webpackChunkName: "component---src-templates-career-template-js" */),
  "component---src-templates-default-template-js": () => import("./../../../src/templates/default-template.js" /* webpackChunkName: "component---src-templates-default-template-js" */),
  "component---src-templates-news-template-js": () => import("./../../../src/templates/news-template.js" /* webpackChunkName: "component---src-templates-news-template-js" */),
  "component---src-templates-offplan-property-details-js": () => import("./../../../src/templates/offplan-property-details.js" /* webpackChunkName: "component---src-templates-offplan-property-details-js" */),
  "component---src-templates-property-details-js": () => import("./../../../src/templates/property-details.js" /* webpackChunkName: "component---src-templates-property-details-js" */),
  "component---src-templates-team-template-js": () => import("./../../../src/templates/team-template.js" /* webpackChunkName: "component---src-templates-team-template-js" */),
  "component---src-templates-video-template-js": () => import("./../../../src/templates/video-template.js" /* webpackChunkName: "component---src-templates-video-template-js" */)
}

