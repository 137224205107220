/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import CheckWebpFeature from "./src/components/common/ggfx-client/module/webp-checker"
export { wrapRootElement } from "./src/apollo/wrap-root-element";
CheckWebpFeature();

export const shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition
}) => {

    // Avoid to scroll to top when next page trigger click
    // But this feature should condiser with details page back scroll too
    
    if (location.pathname.match(/properties-for-sale/)) {
        return false;
    }
    if (location.pathname.match(/properties-for-rent/)) {
        return false;
    }
    if (location.pathname.match(/map-of-properties-for-sale/)) {
        return false;
    }
    if (location.pathname.match(/map-of-properties-for-rent/)) {
        return false;
    }
    if (location.pathname.match(/off-plan-developments/)) {
        return false;
    }
    if (location.pathname.match(/map-of-off-plan-developments/)) {
        return false;
    }

    return true;
};
