import fetch from 'cross-fetch';
import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from "@apollo/client/link/context";

const httpLink = createHttpLink({
    uri: process.env.GATSBY_STRAPI_SRC+"/graphql",
    fetch
});

const authLink = setContext((_, { headers }) => {
    return {
        headers: {
        ...headers,
            authorization: `Bearer ${process.env.GATSBY_STRAPI_AT}`,
        }
    }
});

export const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
});
